<template>
    <div class="scrolllayout" @click="handleswiperchoose" >
      <loader v-if="loading"></loader>
                <diybar :diybarData="diybarData" @handlediybar="handlediybar"></diybar>
<!--                按轮播顺序第一页-->
                <div :class="swiper1[0].swiperclass==0?'noswiper':'swiper'" :style="'z-index:'+swiper1[0].indexz">
<!--                    背景图-->

                    <img class="bac"
                         :src="swiper1[0].bac" alt="">

                      <img src="@/img/plane.png" class="plane" :style="componentData?'right:51%':''" alt="">

<!--                    遮罩以上-->
                    <div class="shadow">

                        <div class="swiper-unit" >
                            <div class="zhfn" style="font-size: 45px;
                            position: absolute;
                            left: 0%;
                            width: 100%;
                            top: 28%;
                            font-weight: bold;" :style="isShow==0?'opacity:1;transition-delay: 0.5s;transform:scaleX(1);':'opacity:0;transition-delay: 0s;transform:scaleX(5);'">
                                <div class="zhfn-left">
                                    <span class="zhfn-left-text">智慧</span>赋能
                                </div>
                                <div class="zhfn-right">
                                    用鑫<span class="zhfn-right-text">成就</span>
                                </div>
                            </div>
                            <div class="opchange" :style="isShow==0?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
                              <div class="center2" >
                                <div class="center2-top">走进鑫嵘</div>
                              </div>
                              <div class="center3" >
                                <div class="center2-bottom-top">
                                  高新技术企业
                                </div>
                                <div class="center2-bottom-bottom">
                                  广东股权交易中心展示企业
                                </div>
                              </div>
                              <div class="bottom" >
                                <div class="bottom-left">
                                  <div class="bottom-left-unit">
                                    <div class="bottom-left-unit-top1">7+</div>
                                    <div class="bottom-left-unit-bottom1" :style="componentData?'font-size:13px':''">7年信息技术</div>
                                    <div class="bottom-left-unit-bottom2" :style="componentData?'font-size:13px':''">开发经验</div>
                                  </div>
                                  <div class="bottom-left-left">
                                    <div class="bottom-left-unit-top2">100+</div>
                                    <div class="bottom-left-unit-bottom1" :style="componentData?'font-size:13px':''">100+软件著作权</div>
                                    <div class="bottom-left-unit-bottom2" :style="componentData?'font-size:13px':''">知识产权</div>
                                  </div>
                                  <div class="bottom-left-left">
                                    <div class="bottom-left-unit-top3">1600+</div>
                                    <div class="bottom-left-unit-bottom1" :style="componentData?'font-size:13px':''">1600家不同行业</div>
                                    <div class="bottom-left-unit-bottom2" :style="componentData?'font-size:13px':''">个人及企事业单位服务经验</div>
                                  </div>
                                </div>
                                <div class="bottom-right">
<!--                                  <div class="bottom-right-top" style="text-indent: 28px">-->
<!--                                    <span class="bottom-right-main">汕头市鑫煌网络科技有限公司</span> （简 称：鑫煌科技），是一家为企业数字化转型、-->
<!--                                    企业信息化建设、企业品牌建设等提供优质高新技术系统产品和先进解决方案、标准化管理的软件开发科技企业。公司在历经七年的技术运营中，技术和服务深得客户青睐；为多家同行提供技术开发和技术支持服务；企业拥有多个知识产权，并已荣获高新技术企业称号。-->
<!--                                  </div>-->
                                  <div class="bottom-right-bottom">
                                    <span class="bottom-right-main">广东鑫嵘科技科技有限公司</span>（简 称：鑫嵘科技）（曾 用 名：鑫煌科技），是一家为企业数字化转型、企业信息化建设、企业品牌建设等提供优质高新技术系统产品和先进解决方案、标准化管理的软件开发科技企业。公司在历经七年的技术运营中，技术和服务深得客户青睐；为多家同行提供技术开发和技术支持服务；企业拥有多个知识产权，并已荣获高新技术企业称号。
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                    <!--                按轮播顺序第二页-->
                <div :class="swiper1[1].swiperclass==0?'noswiper':(swiper1[1].swiperclass==1?'reswiper':'swiper')" :style="'z-index:'+swiper1[1].indexz">
<!--                    背景图-->
                    <img class="bac"
                         :src="swiper1[1].bac" alt="">
<!--                    遮罩以上-->
                    <div class="shadow">
                        <div class="shadowup" :style="componentData?'margin-left:40%':''">
                            <div class="blueblock"></div>
                            <div class="imgbox">
                                <div class="shadow1" >
                                    <div class="opchange"  :style="isShow==1?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
                                      <div class="shadow1-title" >
                                        使命
                                      </div>
                                    </div>

                                  <div class="opchange"  :style="isShow==1?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
                                    <div class="shadow1-item">
                                      提升软件领域
                                    </div>
                                  </div>

                                  <div class="opchange"  :style="isShow==1?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
                                    <div class="shadow1-item">
                                      标准化 信息化
                                    </div>
                                  </div>

                                  <div class="opchange"  :style="isShow==1?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
                                    <div class="shadow1-item">
                                      专业化水平
                                    </div>
                                  </div>


                                </div>
                                <img class="img" src="@/img/xh2up.jpg" alt="">
                            </div>
                        </div>
                      <div class="opchange"  :style="isShow==1?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">

                        <div class="swiper-unit">
                            <div class="second-swiper"  >
                                <div class="wy" style="font-size: var(--b-t-size);margin-bottom: 25px;">
                                    态度丨共赢丨真诚丨共创
                                </div>
                                <div class="second-swiper-center2">
                                    <div class="second-swiper-center2-left"></div>
                                    <div class="second-swiper-center2-right" :style="componentData?'width:386px':''">
                                      鑫嵘科技 · 以汇聚高新技术智慧 用心塑造智能时代的<span
                                            class="second-swiper-center2-right-color1">品牌主张</span>，科技探索永不止步 创新科技无限未来的<span
                                            class="second-swiper-center2-right-color2">企业宗旨</span>，不断的探索，通过不断的调研，产品设计、打磨，研发出了一系列针对各个行业专属的技术解决方案、一站式服务，为用户提供准确的技术及品牌建设为一体的多元服务。
                                    </div>
                                </div>
                                <div class="wy" :style="componentData?'font-size: 25px;margin-left: 600px;margin-top: 30px;':'font-size: 25px;margin-left: 556px;margin-top: 65px;'">
                                    科技向善 驱动未来
                                </div>
                            </div>
                        </div>
                      </div>
                        <!--                    <div class="swiper1-unit" style="animation-delay: 8s">-->
                        <!--                        <div class="blueblock"></div>-->
                        <!--                        <div class="imgbox">-->
                        <!--                            <img class="img" src="@/img/xh2up.jpg" alt="">-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                    </div>
                </div>
<!--                    第三页轮播-->
                <div :class="swiper1[2].swiperclass==0?'noswiper':(swiper1[2].swiperclass==1?'reswiper':'swiper')" :style="'z-index:'+swiper1[2].indexz">
<!--                    背景图-->
                    <img class="bac"
                         :src="swiper1[2].bac" alt="">



<!--                    遮罩以上-->
                    <div class="shadow">
                        <div class="shadowup1" :style="componentData?'margin-left:57%':''">
                            <div class="blueblock1"></div>
                            <div class="imgbox1">
                                <div class="shadow2"></div>
                                <img class="img" src="@/img/xh3up.jpg" alt="">
                            </div>
                        </div>

                      <div class="opchange" :style="isShow==2?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
                        <div class="swiper-unit">
                            <div class="second-swiper" >
                                <div class="wy" style="font-size: var(--b-t-size);margin-bottom: 25px;">
                                    为价值而生 以责任为美
                                </div>
                                <div class="second-swiper-center2">
                                    <div class="second-swiper-center2-left"></div>
                                    <div class="second-swiper-center2-right" :style="componentData?'width:690px':'width: 800px'">
                                        <div class="second-swiper-center2-right-item">
                                          鑫嵘科技·以“<span class="second-swiper-center2-right-item-main">数字、创新、连接、共生</span>”作为公司践行社会责任的核心理念，始终坚持服务实体经济，创造社会长期价值，促进可持续发展。公司依托信息技术开发、人工智能、数字技术等时代前沿技术，建立并发展起核心的数字化建设能力、品牌建设能力、行业理解能力和SaaS模式的企业服务能力。
                                        </div>
                                        <div class="second-swiper-center2-right-item">
                                          公司拥有一支具有综合能力的精英团队，并搭建起以“客户成功”为核心方向的战略合作伙伴矩阵，为客户提供战略策划、视觉设计、技术实施、运营支持、内容输出等基于品牌战略的服务。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
<!--                    第四页轮播-->
                <div :class="swiper1[3].swiperclass==0?'noswiper':'reswiper'" :style="'z-index:'+swiper1[3].indexz">
<!--                    背景图-->
                    <img class="bac"
                         :src="swiper1[3].bac" alt="">
                    <div class="shadow">

                        <div class="swper3-top">

                            <div class="swper3-top-left">
                              <div class="opchange"  :style="isShow==3?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">

                                <div class="wy" style="font-size: 26px;">

                                  同生共创
                                </div>
                                <div class="wy" style="font-size: 26px;">

                                  做真正有温度 有担当的品牌
                                </div>
                                <div class="swper3-top-left-item2">
                                  与信息技术携手共进的七年历程里，我们从未想过去颠覆，去抢谁的生意，未来更不会去打造封闭的生态。因此，我们收获了许多同行的合作，也累积了更多的企业服务经验。在基于品牌战略服务的指引下，我们与众多设计、策划创意、数字化创意、人工智能等综合人才和机构合作，我们认为，产品数字化、品牌化的市场足够大，同生与共创必然会成为主流的市场生态。
                                </div>
                                <div class="swper3-top-left-item2">
                                  我们期待着能与现在的、未来的合作伙伴共同努力，同心携手，以价值为核心，以责任为灯塔，以创新和探索为灵魂，服务每一座城市，每一个企业，用科技创新，创造出每个人的美好未来。
                                </div>
                              </div>

                            </div>
                          <div class="swper3-top-right" :style="componentData?'right:8%':''">
                              <div class="zhfn" :style="isShow==3?'opacity:1;transition-delay: 0.5s;transform:scaleX(1);':'opacity:0;transition-delay: 0s;transform:scaleX(5);'">
                                <div class="zhfn-left">
                                  <span class="zhfn-left-text">智慧</span>赋能
                                </div>
                                <div class="zhfn-right">
                                  用鑫<span class="zhfn-right-text">成就</span>
                                </div>
                              </div>
                            <div class="opchange"  :style="isShow==3?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">

                                  <div class="xhhc"  @click.stop @click="componentData?(ishover=[0,0,0,0],ishover[3]=1):''">
                                    <div class="hoverpadding" v-if="!componentData"></div>
                                    <div class="wx2"  v-if="!componentData">
                                      <div class="wx1-name">
                                        陈小姐：16626116768
                                      </div>
                                      <img src="@/img/wxcode1.png" class="wx1code" alt="">
                                      <div class="wx1-remark">
                                        扫一扫添加微信
                                      </div>
                                    </div>
                                    <div class="wx2-1" v-else :style="ishover[3]?'transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);opacity: 1;':'transform: translateY(0) translateX(-50%) scaleX(0) scaleY(0);opacity: 0;'">
                                      <div class="wx1-name">
                                        陈小姐：16626116768
                                      </div>
                                      <img src="@/img/wxcode1.png" class="wx1code" alt="">
                                      <div class="wx1-remark">
                                        扫一扫添加微信
                                      </div>
                                    </div>
                                    <div class="xhhc-left">加入我们</div>
                                    <div class="xhhc-right">鑫嵘科技</div>
                                  </div>
                              </div>
                            </div>
                        </div>

                      <div class="form-footer" >
                        <div class="form-footer-top">
                          <div class="form-footer-top-item1">
                            <div class="form-footer-top-item1-top">
                              联系我们
                            </div>
                            <div class="form-footer-top-item1-center"></div>
                            <div class="form-footer-top-item1-bottom">
                              感谢您访问鑫嵘科技官方网站，如果您需要我们为您提供服务，欢迎拨打热线咨询，或者直接填写表单内容提交，我们会尽快给您答复，谢谢
                            </div>
                          </div>
                          <div class="form-footer-top-item2" @click.stop @keydown.stop @mousewheel.stop>
                            <div class="form-item">
                              <div class="form-label">
                                姓名
                              </div>
                              <input type="text" :class="tip[0].isShow?'form-input-tip':'form-input'" :placeholder="tip[0].showText" @focus="backscreen" v-model="form.name">
                            </div>
                            <div class="form-item">
                              <div class="form-label">
                                手机
                              </div>
                              <input type="number" :class="tip[1].isShow?'form-input-tip':'form-input'" :placeholder="tip[1].showText" @focus="backscreen" v-model="form.phone">
                            </div>
                            <div class="form-item">
                              <div class="form-label">
                                职务
                              </div>
                              <input type="text" :class="tip[2].isShow?'form-input-tip':'form-input'" @focus="backscreen" :placeholder="tip[2].showText" v-model="form.service">
                            </div>
                            <div class="form-textarea-item">
                              <div class="form-label" style="padding-bottom: 0;padding-top: 2px">
                                需求
                              </div>
                              <div class="form-submit-page">
                                <textarea :class="tip[3].isShow?'form-textarea-tip':'form-textarea'" v-model="form.need" :placeholder="tip[3].showText" @focus="backscreen" cols="30" rows="10"></textarea>
                                <!--                                          <button :class="tip[4].isShow?'form-submit-tip':'form-submit'" @click="handlesubmit" @click.stop>-->
                                <button class="form-submit" @click="handlesubmit" @click.stop>
                                  <span class="form-submit-main">提交</span>
                                </button>
                              </div>
                            </div>

                          </div>

                          <div class="form-footer-top-item4">
                            <div class="form-footer-top-item4-top">
                              <!--                                        <div class="form-footer-top-item4-top-item">-->
                              <!--                                            LOGO-->
                              <!--                                        </div>-->
                              <div class="form-footer-top-item4-top-item">
                                咨询热线：13546861453
                              </div>
                              <div class="form-footer-top-item4-top-item" style="display: flex;flex-direction: row;flex-wrap: wrap">
                                <div>公司地址：广东省汕头市龙湖区科技东路</div><div>智造云谷1号楼806-807</div>
                              </div>
                            </div>
                            <!--                                    <div class="form-footer-top-item4-bottom">-->
                            <!--                                        <div class="form-footer-top-item4-bottom-item">-->
                            <!--                                            <img class="img" src="@/img/form1.png" alt="">-->
                            <!--                                        </div>-->
                            <!--                                        <div class="form-footer-top-item4-bottom-item">-->
                            <!--                                          <div class="wx1">-->
                            <!--                                            <div class="wx1-name">-->
                            <!--                                              陈小姐：13546861453-->
                            <!--                                            </div>-->
                            <!--                                            <img src="@/img/wxcode.png" class="wx1code" alt="">-->
                            <!--                                            <div class="wx1-remark">-->
                            <!--                                              扫一扫添加微信-->
                            <!--                                            </div>-->
                            <!--                                          </div>-->
                            <!--                                            <img class="img" src="@/img/form2.png" alt="">-->
                            <!--                                        </div>-->
                            <!--                                        <div class="form-footer-top-item4-bottom-item">-->
                            <!--                                            <img class="img" src="@/img/form3.png" alt="">-->
                            <!--                                        </div>-->
                            <!--                                        <div class="form-footer-top-item4-bottom-item">-->
                            <!--                                            <img class="img" src="@/img/form4.png" alt="">-->
                            <!--                                        </div>-->
                            <!--                                        <div class="form-footer-top-item4-bottom-item">-->
                            <!--                                            <img class="img" src="@/img/form4.png" alt="">-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                          </div>
                          <div class="form-footer-top-item5">
                            <div class="form-footer-top-item5-item" @click.stop style="flex: none">
                              <div class="form-footer-top-item5-item-top">添加陈小姐微信号</div>
                              <div class="form-footer-top-item5-item-bottom">
                                <img src="@/img/wxcode.png" class="img" alt="">
                              </div>
                              <div class="hoverpadding" v-if="!componentData"></div>
                              <div class="wx2" style="left: -50%;
                                                              transform-origin: 100% 100%">
                                <div class="wx1-name">
                                  陈小姐微信号
                                </div>
                                <img src="@/img/wxcode.png" class="wx1code" alt="">
                                <div class="wx1-remark">
                                  扫一扫添加微信
                                </div>
                              </div>
                            </div>
                            <div class="form-footer-top-item5-item" style="flex: none" @click.stop>
                              <div class="form-footer-top-item5-item-top">关注鑫嵘科技公众号</div>
                              <div class="form-footer-top-item5-item-bottom">
                                <img class="img" src="@/img/code1.jpg" alt="">
                              </div>
                              <div class="hoverpadding" v-if="!componentData"></div>
                              <div class="wx2" style="left: -50%;
                                                              transform-origin: 100% 100%">
                                <div class="wx1-name">
                                  鑫嵘科技公众号
                                </div>
                                <img src="@/img/code1.jpg" class="wx1code" alt="">
                                <div class="wx1-remark">
                                  扫一扫关注公众号
                                </div>
                              </div>
                            </div>
                            <div class="form-footer-top-item5-item" style="flex: none" @click.stop >
                              <div class="form-footer-top-item5-item-top">进入鑫嵘科技微信小程序</div>
                              <div class="form-footer-top-item5-item-bottom">
                                <img class="img" :src="base_url+'/public/images/code3.jpg'" alt="">
                              </div>
                              <div class="hoverpadding" v-if="!componentData"></div>
                              <div class="wx2" style="left: -50%;
                                                              transform-origin: 100% 100%">
                                <div class="wx1-name">
                                  鑫嵘科技微信小程序
                                </div>
                                <img :src="base_url+'/public/images/code3.jpg'" class="wx1code" alt="">
                                <div class="wx1-remark">
                                  扫一扫进入微信小程序
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-footer-bottom">
                          <a class="form-footer-bottom-item">Copyright 2014-2021 xinhuang.net.cn 版权左右</a>
                          <!--                                <a class="form-footer-bottom-item">京ICP备1234567号-01</a>-->
                          <!--                                <a class="form-footer-bottom-item">京IPC证0754888号</a>-->
                          <!--                                <a class="form-footer-bottom-item">隐私声明</a>-->
                          <!--                                <a class="form-footer-bottom-item">资质证照</a>-->
                          <div class="form-footer-bottom-item"></div>
                          <!--                                <a class="form-footer-bottom-item">网站支持IPv6</a>-->
<!--                          <a class="form-footer-bottom-item">@鑫煌科技</a>-->
                          <a class="form-footer-bottom-item">@鑫嵘科技</a>
                          <!--                                <a class="form-footer-bottom-item">创合文化</a>-->
                          <!--                                <a class="form-footer-bottom-item">潮策全品</a>-->
                          <!--                                <a class="form-footer-bottom-item">星物种</a>-->
                          <!--                                <a class="form-footer-bottom-item">即刻现场</a>-->
                          <!--                                <a class="form-footer-bottom-item">行政通</a>-->
                          <!--                                <a class="form-footer-bottom-item">即刻SHOW NEW BRAND</a>-->
                        </div>
                      </div>

<!--                      <div class="opchange"  :style="isShow==3?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">-->
                        <div class="form-footer-1" >

                          <div class="form-footer-top-1" :style="componentData?' height: 200px;padding-bottom: 0;':''">
                            <div class="form-footer-top-item1-1">
                              <div class="form-footer-top-item1-top-1">
                                联系我们
                              </div>
                              <div class="form-footer-top-item1-item-1" :style="componentData?'margin-right:5px;width: 165px;font-size: 13px;text-indent: 26px;margin-top: 15px;':''">
                                感谢您访问鑫嵘科技官方网站，如果您需要我们为您提供服务，欢迎拨打热线咨询，或者直接填写表单内容提交，我们会尽快给您答复，谢谢
                              </div>
                              <div class="form-footer-top-item1-item-1" :style="componentData?'margin-top: 39px;text-indent: 0;margin-right:5px;width: 165px;font-size: 13px;':'text-indent: 0;'">
                                咨询热线：13546861453
                              </div>
                              <div class="form-footer-top-item1-item-1" :style="componentData?'display: flex;flex-direction: row;flex-wrap: wrap;text-indent: 0;width: 165px;font-size: 13px;':'text-indent: 0;'">
                                <div  v-if="!componentData">公司地址：广东省汕头市龙湖区科技东路</div><div  v-if="!componentData">智造云谷1号楼806-807</div>
                                <div v-if="componentData">
                                  公司地址：广东省汕头市龙湖区科技东路智造云谷1号楼806-807
                                </div>
                              </div>

                            </div>
                            <div class="form-footer-top-item2-1" @click.stop @keydown.stop @mousewheel.stop>
                              <div class="form-item-1">
                                <div class="form-label-1">
                                  姓名
                                </div>
                                <input type="text" :class="tip[0].isShow?'form-input-tip-1':'form-input-1'" :placeholder="tip[0].showText" v-model="form.name" @focus="backscreen">
                              </div>
                              <div class="form-item-1">
                                <div class="form-label-1">
                                  手机
                                </div>
                                <input type="text" :class="tip[1].isShow?'form-input-tip-1':'form-input-1'" :placeholder="tip[1].showText" v-model="form.phone" @focus="backscreen">
                              </div>
                              <div class="form-item-1">
                                <div class="form-label-1">
                                  职务
                                </div>
                                <input type="text" :class="tip[2].isShow?'form-input-tip-1':'form-input-1'" :placeholder="tip[2].showText" v-model="form.service" @focus="backscreen">
                              </div>
                              <div class="form-item-1">
                                <div class="form-label-1">
                                  需求
                                </div>
                                <input :class="tip[3].isShow?'form-input-tip-1':'form-input-1'" type="text" :placeholder="tip[3].showText" v-model="form.need" @focus="backscreen">

                              </div>
                              <!--                                  <button :class="tip[4].isShow?'form-submit-tip-1':'form-submit-1'" @click.stop @click="handlesubmit">-->
                              <button class="form-submit-1" @click.stop @click="handlesubmit">
                                <span class="form-submit-main-1">提交</span>
                              </button>
                            </div>

                            <!--                                <div class="form-footer-top-item4">-->

                            <!--                                    <div class="form-footer-top-item4-bottom-1">-->
                            <!--&lt;!&ndash;                                        <div class="form-footer-top-item4-bottom-item-1">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                            <img class="img" src="@/img/form1.png" alt="">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
                            <!--                                        <div class="form-footer-top-item4-bottom-item-1">-->
                            <!--                                            <div class="wx1">-->
                            <!--                                              <div class="wx1-name">-->
                            <!--                                                陈小姐：13546861453-->
                            <!--                                              </div>-->
                            <!--                                              <img src="@/img/wxcode.png" class="wx1code" alt="">-->
                            <!--                                              <div class="wx1-remark">-->
                            <!--                                                扫一扫添加微信-->
                            <!--                                              </div>-->
                            <!--                                            </div>-->
                            <!--                                            <img class="img" src="@/img/form2.png" alt="">-->
                            <!--                                        </div>-->
                            <!--&lt;!&ndash;                                        <div class="form-footer-top-item4-bottom-item-1">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                            <img class="img" src="@/img/form3.png" alt="">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        <div class="form-footer-top-item4-bottom-item-1">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                            <img class="img" src="@/img/form4.png" alt="">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <div class="form-footer-top-item5-1">
                              <div class="form-footer-top-item5-item-1" @click.stop @click="ishover=[0,0,0,0],ishover[0] = 1">
                                <div class="form-footer-top-item5-item-bottom-1">
                                  <img class="img" src="@/img/wxcode.png" alt="">
                                </div>
                                <div class="form-footer-top-item5-item-top-1">添加陈小姐微信号</div>
                                <div class="hoverpadding" v-if="!componentData"></div>
                                <div class="wx2" v-if="!componentData" style="left: -50%;transform-origin: 100% 100%">
                                  <div class="wx1-name">
                                    陈小姐微信号
                                  </div>
                                  <img src="@/img/wxcode.png" class="wx1code" alt="">
                                  <div class="wx1-remark">
                                    扫一扫添加陈小姐微信号
                                  </div>
                                </div>
                                <div class="wx4" :style="ishover[0]?'transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);opacity: 1;':'transform: translateY(0) translateX(-50%) scaleX(0) scaleY(0);opacity: 0;'">
                                  <div class="wx1-name">
                                    陈小姐微信号
                                  </div>
                                  <img src="@/img/wxcode.png" class="wx1code" alt="">
                                  <div class="wx1-remark">
                                    扫一扫添加陈小姐微信号
                                  </div>
                                </div>

                              </div>


                              <div class="form-footer-top-item5-item-1" @click.stop @click="ishover=[0,0,0,0],ishover[1] = 1">
                                <div class="form-footer-top-item5-item-bottom-1">
                                  <img class="img" src="@/img/code1.jpg" alt="">
                                </div>
                                <div class="form-footer-top-item5-item-top-1">关注鑫嵘科技公众号</div>
                                <div class="hoverpadding" v-if="!componentData"></div>

                                <div  class="wx2" v-if="!componentData" style="left: -50%;transform-origin: 100% 100%">

                                  <div class="wx1-name">
                                    鑫嵘科技公众号
                                  </div>
                                  <img src="@/img/code1.jpg" class="wx1code" alt="">
                                  <div class="wx1-remark">
                                    扫一扫关注鑫嵘科技公众号
                                  </div>
                                </div>
                                <div class="wx4" v-else :style="ishover[1]?'transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);opacity: 1;':'transform: translateY(0) translateX(-50%) scaleX(0) scaleY(0);opacity: 0;'">
                                  <div class="wx1-name">
                                    鑫嵘科技公众号
                                  </div>
                                  <img src="@/img/code1.jpg" class="wx1code" alt="">
                                  <div class="wx1-remark">
                                    扫一扫关注鑫嵘科技公众号
                                  </div>
                                </div>
                              </div>
                              <div class="form-footer-top-item5-item-1" @click.stop @click="ishover=[0,0,0,0],ishover[2] = 1">
                                <div class="form-footer-top-item5-item-bottom-1">
                                  <img class="img" :src="base_url+'/public/images/code3.jpg'" alt="">
                                </div>
                                <div class="form-footer-top-item5-item-top-1">进入鑫嵘科技微信小程序</div>
                                <div class="hoverpadding" v-if="!componentData"></div>

                                <div  class="wx2" v-if="!componentData" style="left: -50%;transform-origin: 100% 100%">
                                  <div class="wx1-name">
                                    鑫嵘科技微信小程序
                                  </div>
                                  <img :src="base_url+'/public/images/code3.jpg'" class="wx1code" alt="">
                                  <div class="wx1-remark">
                                    扫一扫进入微信小程序
                                  </div>
                                </div>
                                <div class="wx4" v-else :style="ishover[2]?'transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);opacity: 1;':'transform: translateY(0) translateX(-50%) scaleX(0) scaleY(0);opacity: 0;'">
                                  <div class="wx1-name">
                                    鑫嵘科技微信小程序
                                  </div>
                                  <img :src="base_url+'/public/images/code3.jpg'" class="wx1code" alt="">
                                  <div class="wx1-remark">
                                    扫一扫进入微信小程序
                                  </div>
                                </div>


                              </div>

                            </div>
                          </div>
                          <div class="form-footer-bottom-1">
                            <div class="form-footer-bottom-page-1">
                              <a class="form-footer-bottom-item">Copyright 2014-2021 xinhuang.net.cn 版权左右</a>
                              <!--                                    <a class="form-footer-bottom-item">京ICP备1234567号-01</a>-->
                              <!--                                    <a class="form-footer-bottom-item">京IPC证0754888号</a>-->
                              <!--                                    <a class="form-footer-bottom-item">隐私声明</a>-->
                              <!--                                    <a class="form-footer-bottom-item">资质证照</a>-->
                              <div class="form-footer-bottom-item"></div>
                            </div>
                            <div class="form-footer-bottom-page-1">
                              <!--                                    <a class="form-footer-bottom-item">网站支持IPv6</a>-->
                              <a class="form-footer-bottom-item">@鑫煌科技</a>
                              <a class="form-footer-bottom-item">@鑫嵘科技</a>
                              <!--                                    <a class="form-footer-bottom-item">创合文化</a>-->
                              <!--                                    <a class="form-footer-bottom-item">潮策全品</a>-->
                              <!--                                    <a class="form-footer-bottom-item">星物种</a>-->
                              <!--                                    <a class="form-footer-bottom-item">即刻现场</a>-->
                              <!--                                    <a class="form-footer-bottom-item">行政通</a>-->
                              <!--                                    <a class="form-footer-bottom-item">即刻SHOW NEW BRAND</a>-->
                            </div>
                          </div>
                        </div>
<!--                      </div>-->

                        <div class="shadow" style="z-index: 2" v-if="successtip" @click="handlesuccesstip" @click.stop>
                          <div class="tipbox-1">
                            <svg width="84" height="84" class="svg">
                              <polyline fill="none" stroke="#000000" stroke-width="4" points="18.48,44.94 36.33,59.41 63.84,28.98" stroke-linecap="round" stroke-linejoin="round" class="tick"></polyline>
                            </svg>
                            <div class="tipbox-success">
                              提交成功
                            </div>
                            <div class="tipbox-remark">
                              需求提交成功，我们将尽快给您答复<br>
                              您也可以拨打咨询热线咨询客服
                            </div>
                            <div class="tipbox-time">
                              {{time}}s
                            </div>
                          </div>
                        </div>
                        <div class="shadow" style="z-index: 2" v-if="failtip" @click="handlefailtip" @click.stop>
                          <div class="tipbox-1">
                            <svg width="80" height="80" class="svg">
                              <polyline fill="none" stroke="#000000" stroke-width="4" points="20,20 60,60" stroke-linecap="round" stroke-linejoin="round" class="fail-left"></polyline>
                              <polyline fill="none" stroke="#000000" stroke-width="4" points="60,20 20,60" stroke-linecap="round" stroke-linejoin="round" class="fail-right"></polyline>
                            </svg>
                            <div class="tipbox-success">
                              {{ failtext }}
                            </div>
                            <div class="tipbox-remark">
                              需求提交失败<br>
                              您可以拨打咨询热线咨询客服
                            </div>
                            <div class="tipbox-time">
                              {{time}}s
                            </div>
                          </div>
                        </div>



<!--                        <div class="tipbox" v-if="successtip">-->
<!--                          <div class="tipbox-top"></div>-->
<!--                          <div class="tipbox-right"></div>-->
<!--                          <div class="tipbox-bottom"></div>-->
<!--                          <div class="tipbox-left"></div>-->

<!--                          <div class="tip">提交成功</div>-->


<!--                        </div>-->






























                    </div>
                </div>








    </div>
</template>

<script>
    import bac1 from '../img/xh1.jpg'
    import bac2 from '../img/xh2.jpg'
    import bac3 from '../img/xh3.jpg'
    import bac4 from '../img/xh4.jpg'
    //白色进度条
    import diybar from "../components/diybar";
    import axios from "axios";
    import {base_url} from "../api/base_url";

    export default {
        data() {
            return {
              ishover:[0,0,0,0],
              //加载
              loading:false,
              timer:null,
              isShow:-1,
                //轮播到哪一页，0为第一页，1为第二页，2为第三页，3为第四页，-1为跳转上一页路由，4为跳转下一页路由
                swiperchoose: 0,
                //记录变化前的swiperchoose
                oddswiperchoose:0,
                base_url:base_url(),
                //弹窗提示
                tip:[
                  {
                    isShow:0,
                    showText:''
                  },
                  {
                    isShow:0,
                    showText:''
                  },
                  {
                    isShow:0,
                    showText:''
                  },
                  {
                    isShow:0,
                    showText:''
                  },
                ],
                time:3,
                successtip:0,

                failtip:0,
                failtext:'服务器错误',

                //界面宽度的一半
                resizeX:0,


                //节流，阻止事件
                preventcontact:0,


                //轮播
                swiper1: [
                    {
                        //背景图
                        bac: bac1,
                        //高度
                        indexz:99,
                        //判断左移还是右移的class,1为左移，-1为右移，0为进入页面不动的默认情况
                        swiperclass:0,
                        diybarposition:0,
                    },
                    {
                        bac: bac2,
                        indexz:-1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:0,
                    },
                    {
                        bac: bac3,
                        indexz:-1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:0,
                    },
                    {
                        bac: bac4,
                        indexz:1,
                        //判断左移还是右移的class
                        swiperclass:0,
                        diybarposition:1,
                    }
                ],
                //diybar配置
                diybarData:{
                    //节点数量
                    count:4,
                    //目标节点初始位置
                    targetleft:35,
                    //跳转页面之后创建，跳转页面之前删除
                    beforedetroy:true,
                    //目标节点次序
                    target:0,
                },
                //表单内容
                form:{
                    name:'',
                    phone:'',
                    service:'',
                    need:'',
                    remark:'',
                },
            }
        },
        components:{
            diybar,
        },
        props:{
            componentData:{
                default:0,
                type:Number,
            }
        },
        created() {




            //监听鼠标滚动
            setTimeout(()=>{

              window.addEventListener('mousewheel',this.handlemouse)
            },500)
            //监听网页宽度
            window.addEventListener('resize',this.resize)
            this.resize()

            //监听按键
            setTimeout(()=>{
                    window.addEventListener('keydown',this.keydown)
            },500)
            let i = this.$route.query.target
            if(i){
              this.diybarData.target = i
              this.swiperchoose = i
            }


            this.isShow  = -1
            setTimeout(()=>{
              this.isShow = this.swiperchoose
            },100)
        },
        beforeUnmount() {
            this.diybarData.beforedetroy = false
        },
        methods: {
          handlesuccesstip(){
            clearInterval(this.timer)
            this.successtip=0
            this.time = 4
          },
          handlefailtip(){
            clearInterval(this.timer)
            this.failtip=0
            this.time = 4
          },
          //提交
          handlesubmit(){
            if(this.preventcontact){
              return;
            }
            this.preventcontact = 1
            if(!this.form.name){
              this.tip[0].isShow = 1
              this.tip[0].showText = '请输入姓名'
              // this.tip[4].isShow = 1
              this.preventcontact = 0
              return
            }
            this.tip[0].isShow = 0
            this.tip[0].showText = ''
            if(!this.form.phone){
              this.tip[1].isShow = 1
              this.tip[1].showText = '请输入手机'
              // this.tip[4].isShow = 1
              this.preventcontact = 0
              return
            }else{
              if(this.form.phone.toString().length!=11){
                this.tip[1].isShow = 1
                this.form.phone = ''
                this.tip[1].showText = '手机号码错误'
                // this.tip[4].isShow = 1
                this.preventcontact = 0
                return
              }
              // var phone = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/
              var phone = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
                if(!phone.test(this.form.phone)) {
                  console.log(222)
                  this.tip[1].isShow = 1
                  this.form.phone = ''
                  this.tip[1].showText = '手机号码错误'
                  // this.tip[4].isShow = 1
                  this.preventcontact = 0
                  return
                }

            }
            this.tip[1].isShow = 0
            this.tip[1].showText = ''
            if(!this.form.service){
              this.tip[2].isShow = 1
              this.preventcontact = 0
              this.tip[2].showText = '请输入职务'
              // this.tip[4].isShow = 1
              return
            }
            this.tip[2].isShow = 0
            this.tip[2].showText = ''
            if(!this.form.need){
              this.tip[3].isShow = 1
              this.tip[3].showText = '请输入需求'
              // this.tip[4].isShow = 1
              this.preventcontact = 0
              return
            }
            this.tip[3].isShow = 0
            this.tip[3].showText = ''
            //重置弹窗提示

            let inittip=[
              {
                isShow:0,
                showText:''
              },
              {
                isShow:0,
                showText:''
              },
              {
                isShow:0,
                showText:''
              },
              {
                isShow:0,
                showText:''
              },
              {
                isShow:0,
                showText:''
              },
            ]
            this.tip = inittip


            this.loading=true
            let data = this.form
            axios.post(this.base_url+'/applyrecord/gw', {data},{
              headers:{
                'base':'gw'
              },
            }).then(()=>{
              this.loading=false
              this.preventcontact = 0
              this.successtip= 1
              //清空表单内容
              this.form={
                name:'',
                    phone:'',
                    service:'',
                    need:'',
                    remark:'',
              },
              setTimeout(()=>{
                this.timer = setInterval(()=>{
                  this.time=this.time-1
                  if(this.time==0){
                    this.successtip= 0
                    clearInterval(this.timer)
                    this.time = 4
                  }
                },1000)
              },300)
            }).catch(()=>{
              this.loading=false
              this.failtip= 1
              this.preventcontact = 0
              setTimeout(()=>{
                this.timer = setInterval(()=>{
                  this.time=this.time-1
                  if(this.time==0){
                    this.failtip= 0
                    clearInterval(this.timer)
                    this.time = 4
                  }
                },1000)
              },300)
            })
          },
          //全屏
          fullscreen() {
            //全屏
            var el = document.documentElement
            var rfs = el.requestFullScreen||el.webkitRequestFullScreen||el.mozRequestFullScreen||el.msRequestFullScreen
            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
            if(!isFullScreen){
              if(rfs){
                rfs.call(el)
              }
            }
          },
          //退出全屏
          backscreen() {
            //全屏
            var el = document.documentElement
            var rfs = el.requestFullScreen||el.webkitRequestFullScreen||el.mozRequestFullScreen||el.msRequestFullScreen
            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
            if(isFullScreen){
              if(rfs){
                rfs.call(el)
              }
            }
          },
            //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
            handleswiperchoose(e) {
              //监听是否全屏
              let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
              if(!isFullScreen){
                return;
              }
              let k = this.ishover.indexOf(1)
              if(this.componentData&&(k!=-1)){
                return this.ishover = [0,0,0,0];
              }


                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                let X = e.clientX
                // if(this.componentData){
                //     X = e.clientY
                // }
                if(X>=this.resizeX){
                    this.swiperchoose++
                }else{
                    this.swiperchoose--
                }

                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            handlediybar(e){
                if(e.target==this.swiperchoose){
                    return
                }
                this.swiperchoose=e.target
                this.diybarData.target =e.target
            },
            //监听鼠标滚动事件,上滚回退上一张，下滚继续下一张
            handlemouse(e){

                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.deltaY>0){
                    this.swiperchoose++

                }else{

                    this.swiperchoose--


                }

                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            resize(){
                if(this.componentData){
                    this.resizeX = this.componentData/2
                    return
                }
               this.resizeX = document.body.clientWidth/2
            },

            //监听键盘
            keydown(e){
                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                if(e.keyCode==39){
                    this.swiperchoose++
                }else if(e.keyCode==37){
                    this.swiperchoose--
                }else if(e.keyCode==38){
                    this.swiperchoose=-1
                }else if(e.keyCode==40){
                    this.swiperchoose=4
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },
        },

        watch:{
            swiperchoose:function (e) {
              this.isShow = e
              if(e>3){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index+1].router)
                    return
                }else if(e<0){
                    this.handlerouter(this.$store.state.routerlist[this.$route.meta.index-1].router)
                    return;
                }
                if(e==0){
                    this.swiper1[0].indexz=99
                    this.swiper1[2].indexz=-1
                    this.swiper1[3].indexz=-1
                    this.swiper1[1].indexz=-1
                    if(this.oddswiperchoose>=1){
                        this.swiper1[0].swiperclass= -1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                    this.swiper1[3].swiperclass= 0



                }else if(e==1){
                    this.swiper1[1].indexz=99
                    this.swiper1[3].indexz=-1
                    this.swiper1[0].indexz=-1
                    this.swiper1[2].indexz=-1
                    if(this.oddswiperchoose>=2){
                        this.swiper1[1].swiperclass= -1
                    }else{
                        this.swiper1[1].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                    this.swiper1[3].swiperclass= 0
                }else if(e==2){
                    this.swiper1[0].indexz=-1
                    this.swiper1[2].indexz=99
                    this.swiper1[1].indexz=-1
                    this.swiper1[3].indexz=-1
                    if(this.oddswiperchoose>=3){
                        this.swiper1[2].swiperclass= -1

                    }else{
                        this.swiper1[2].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[3].swiperclass= 0
                }else if(e==3){
                    this.swiper1[0].indexz=-1
                    this.swiper1[1].indexz=-1
                    this.swiper1[3].indexz=99
                    this.swiper1[2].indexz=-1
                    if(this.oddswiperchoose<=2){
                        this.swiper1[3].swiperclass= 1
                    }
                    this.swiper1[this.oddswiperchoose].indexz=1
                    this.swiper1[0].swiperclass= 0
                    this.swiper1[1].swiperclass= 0
                    this.swiper1[2].swiperclass= 0
                }
                this.oddswiperchoose = e
                this.diybarData.target = e
                this.diybarData.position = this.swiper1[e].diybarposition

            }
        },
    }

</script>

<style lang="scss" scoped>
    @import "~@/css/button.css";
    /*.cs {*/
    /*    width: 100%;*/
    /*    position: absolute;*/
    /*    left: 0;*/
    /*    top: 0;*/
    /*    bottom: 0;*/
    /*    z-index: 50;*/
    /*}*/

    /*.cs1 {*/
    /*    position: absolute;*/
    /*    inset: 0;*/
    /*    z-index: 60;*/
    /*    background: rgba(0, 0, 0, 0.7);*/
    /*    overflow: hidden;*/
    /*}*/




    //.center2,.center3,.bottom,.second-swiper,.shadow1-title,.shadow1-item,.swper3-top-left-item2,.form-footer,.xhhc{
    //  transition: 0.3s;
    //}
    .plane{
      width: 120px;
      height: 137px;
      position: absolute;
      bottom: -10%;
      right: 53%;
      transform: translateX(-50%) rotate(9.65deg) scale(0.2);
      animation: planego 18s ease-in infinite ;

    }

    @keyframes planego {
      80%,100%{
        //bottom: 100%;
        transform: translateX(50%) translateY(-110vh) rotate(9.65deg) scale(0.6);
      }
    }
    .swiper{
        position: absolute;
        left: -100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnright;
    }
    @keyframes turnright {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    .reswiper{
        position: absolute;
        left: 100%;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: turnleft;
    }
    @keyframes turnleft {
        0% {
            left: 100%;
        }
        100% {
            left: 0;
        }
    }

    .noswiper{
        position: absolute;
        left:0%;
        width: 100%;
        height: 100%;
    }

    .swiper-unit {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }





    /*.swiper1{*/
    /*    position: absolute;*/
    /*    inset: 0;*/
    /*    animation-name: turnleft;*/
    /*    animation-duration: 16s;*/
    /*    animation-iteration-count:infinite;*/
    /*}*/

    /*.scrolllayout-flex{*/
    /*    background: url("../img/xh1.jpg") no-repeat center center fixed;*/
    /*    background-size:cover;*/
    /*}*/
    .center2 {
        position: absolute;
        top: 46%;
        margin-left: 5%;
        right: auto;
        left: 0;
    }

    .center2-top {
        width: auto;
        font-size: var(--b-t-size);
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .center3 {
        position: absolute;
        top: 55%;
        width: 100%;
        margin-left: 5%;
        color: white;
        font-size: var(--s-c-size);
        left: 0;
    }

    .center2-bottom-top {
        margin-bottom: 12px;
    }

    .bottom {
        color: white;
        font-size: var(--b-c-size);
        position: absolute;
        width: 100%;
        /*width: auto;*/
        bottom: 15%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

    }

    .bottom-left {
        margin-left: 5%;
        display: flex;
        flex-direction: row;
        width: 39%;
        justify-content: space-between;
        text-align: center;
        margin-bottom: 7px;
        margin-right: 20px;
    }

    .bottom-left-unit-top1, .bottom-left-unit-top2, .bottom-left-unit-top3 {
        font-size: 30px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .bottom-left-unit-top1 {
        background: linear-gradient(to right, #0179cf, #044371 85%);
        background-clip: text;
    }

    .bottom-left-unit-top2 {
        background: linear-gradient(to right, #0179cf, #044371 95%);
        background-clip: text;
    }

    .bottom-left-unit-top3 {
        background: linear-gradient(to right, #0179cf, #044371 97%);
        background-clip: text;
    }

    .bottom-left-unit-bottom1 {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .bottom-right {
        width: 580px;
        line-height: 2;
        margin-right: 6%;

    }


    .bottom-right-main {
        background: linear-gradient(to right, #f2e6cd, #edd8a5 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .bottom-right-bottom {
        text-indent: 28px;
        line-height: 2;
    }



    /*@keyframes turnup {*/
    /*    0% {*/
    /*        opacity: 0;*/
    /*    }*/
    /*    100% {*/
    /*        opacity: 1;*/
    /*    }*/
    /*}*/





    .blueblock {
        width: 248px;
        height: 331px;
        background: rgba(0, 123, 211, 0.25);
    }

    .imgbox {
        width: 332.7px;
        height: 331px;
        position: relative;
    }

    .shadow1 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: white;

        /*z-index: 1;*/
        background: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .shadowup {
        position: absolute;
        display: flex;
        flex-direction: row;
        margin-left: 58%;
        right: auto;
        top: 35%;
        @media screen and (max-width: 1480px){
            margin-left: 48%;
        }
    }

    .shadow1-title {
        font-size: 49px;
        margin-top: 92.7px;
        margin-bottom: 24px;
    }

    .shadow1-item {
        font-size: var(--b-c-size);
    }

    .second-swiper {
        position: absolute;
        top: 35%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        left: 7%;
    }


    .second-swiper-center2 {
        display: flex;
        flex-direction: row;
    }

    .second-swiper-center2-right {
        font-size: var(--s-t-size);
        color: white;
        flex: 1;
        width: 665px;
        line-height: 2;
        text-indent: 40px;
        @media screen and (max-width:1480px ) {
            width: 540px;
        }
    }

    .second-swiper-center2-left {
        width: 160px;
        height: 3px;
        background: white;
        margin-right: 20px;
        margin-top: 19px;
    }

    .second-swiper-center2-right-color1 {
        color: var(--s-color1);
    }

    .second-swiper-center2-right-color2 {
        color: #ecd296;
    }


    .imgbox1{
        width: 375.2px;
        height: 476.3px;
        position: absolute;
        right: 0;
        top: 0;
    }

    .shadowup1 {
        position: absolute;
        /*display: flex;*/
        /*flex-direction: row;*/
        margin-left: 69%;
        right: auto;
        top: 26%;
        height: 496px;
        width:407.1px;
        @media screen and (max-width: 1440px) {
            margin-left: 63%;
        }
    }
    .blueblock1 {
        height:448px;
        width:407.1px;
        background: rgba(0, 123, 211, 0.4);
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .shadow2{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top:0;
        color: white;
        /*z-index: 1;*/
        background: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .second-swiper-center2-right-item{
        margin-top: 20px;
    }
    .second-swiper-center2-right-item:first-of-type{
        margin-top: 0;
    }
    .second-swiper-center2-right-item-main{
        background: linear-gradient(to right, #f0dfb9, #ecd295 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .swper3-top{
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 21%;
        right: 0;
        left: 0;
        justify-content: space-between;
    }
    .swper3-top-left{
        padding-left: 5%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
    .swper3-top-left-item2{
        font-size: var(--s-c-size);
        color: white;
        width: 504px;
        margin-top: 25px;
        line-height: 1.8;
      text-indent: 28px;
    }
    .swper3-top-right{
        position: absolute;
        right: 18%;
        top: 55%;
        color: white;
    }

    .xhhc{
        margin-top: 41px;
      cursor: pointer;
      position: relative;
    }
    //.xhhc1{
    //  margin-top: 41px;
    //  cursor: pointer;
    //  position: relative;
    //}
    .form-footer{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background:rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1650px){
            display: none;
        }
    }
    .form-footer-1{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background:rgba(0,0,0,0.8);
        flex-direction: column;
        display: none;
        @media screen and (max-width: 1650px){
            display: flex;
        }
    }
    .form-footer-top{
        display: flex;
        flex-direction: row;
      padding-left: 20px;
      padding-right: 50px;
        padding-top: 21px;
        padding-bottom: 24px;
        border-bottom:1px solid #2e3234;
        height: 140px;
        justify-content: space-between;
    }
    .form-footer-top-1{
        display: flex;
        flex-direction: row;
        margin-left: 2%;
        margin-right: 2%;
        padding-top: 19px;
        padding-bottom: 20px;
        border-bottom:1px solid #2e3234;
        height: 125px;
        justify-content: center;
        @media screen and (max-width: 1500px) {
            margin:0;
          @media screen and (max-width: 1400px) {
            height: 150px;
            padding-bottom: 0;

          }
        }
    }
    .form-footer-top-item1{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding-left: 1%;
        width: 337px;
        margin-right: 1%;
    }
    .form-footer-top-item1-1{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        flex-wrap: wrap;
        padding-left: 2%;
        /*width: 290px;*/
        margin-right: 3%;
        width: 630px;
    }
    .form-footer-top-item1-top{
        font-size: var(--s-t-size);
        background: linear-gradient(to left, var(--s-color1) 0%, #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }
    .form-footer-top-item1-item-1{
        font-size: 14px;
        color: white;
        margin-top: 20px;
        width: 345px;
        line-height: 25px;
      margin-right: 20px;
      text-indent: 28px;
      @media screen and (max-width: 1400px){
        width: 235px;
        font-size: 13px;
        text-indent: 26px;
        margin-top: 15px;

      }
    }
    .form-footer-top-item1-item-1:nth-child(3){
      margin-top: 43px;
      @media screen and (max-width: 1400px){

        margin-top: 38px;

      }
    }
    .form-footer-top-item1-item-1:last-child{
        margin-top: 0;
    }
    .form-footer-top-item1-top-1{
        font-size: 17px;
        background: linear-gradient(to left, var(--s-color1) 0%, #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;

    }
    .form-footer-top-item1-center{
        width: 43px;
        height: 1px;
        background: var(--s-color2);
        margin-top: 10px;
        margin-bottom: 7px;

    }
    .form-footer-top-item1-bottom{
        line-height: 1.9;
        font-size: 14px;
        text-indent: 28px;
    }
    .form-footer-top-item2{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        flex-wrap: wrap;
        width: 520px;
    }
    .form-footer-top-item2-1{
        display: flex;
        flex-direction: column;
        align-items: baseline;
      align-self: center;
    }
    .form-submit-page{
        display: flex;
        flex-direction: column;

    }
    .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }
    .form-item-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 7px;
    }
    .form-item:first-of-type{
        margin-top: 0;
    }
    .form-item-1:first-of-type{
        margin-top: 0;
    }
    .form-label{
        font-size: 16px;
        background: linear-gradient(to left, var(--s-color1) 0%, #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: 16px;
        padding-bottom: 2px;

    }
    .form-label-1{
        font-size: 11px;
        background: linear-gradient(to left, var(--s-color1) 0%, #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: 10px;
        padding-bottom: 2px;

    }
    .form-input{
        background: transparent;
        border:2px solid rgba(white,0.5);
        width: 147px;
        height: 31px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
        font-weight: 400;
        color: white;
    }
    .form-input-tip {
      background: transparent;
      border:2px solid rgba(white,0.5);
      width: 147px;
      height: 31px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: white;
      transform: translate(0,0);
      animation: mytip 0.3s forwards ease-in-out;
    }
    @keyframes mytip {
      0%{
        border:2px solid rgba(white,0.5);
        transform: translate(0,0);
      }
      25%{
        transform: translate(2px,0);
      }
      50%{
        transform: translate(0px,0);
      }
      75%{
        transform: translate(-2px,0);
      }
      100%{
        transform: translate(0px,0);
        border:2px solid rgba(red,0.5);
      }
    }
    .form-input-1{
        width: 150px;
        padding-left: 5px;
        padding-right: 5px;
        border:2px solid rgba(white,0.5);
        background: transparent;
        color: white;
        height: 20px;
        font-size: 13px;
    }
    .form-input-tip-1{
      width: 150px;
      padding-left: 5px;
      padding-right: 5px;
      border:2px solid rgba(white,0.5);
      background: transparent;
      color: white;
      height: 20px;
      font-size: 13px;
      transform: translate(0,0);
      animation: mytip 0.3s forwards ease-in-out;
    }
    .form-footer-top-item3{
        margin-left: 20px;
        margin-right: 3%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .form-textarea-item{
        display: flex;
        flex-direction: row;
        margin-left: 10px;
    }
    ::-webkit-scrollbar{
        width: 5px;
        background: var(--m-color);

    }
    ::-webkit-scrollbar-thumb{
        background: rgba(255,255,255,0.3);
        border-radius: 5px;

    }
    .form-textarea{
        width: 254px;
        height: 98px;
        border:2px solid rgba(white,0.5);
        background: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 14px;
        color: white;
    }
    .form-textarea-tip{
      width: 254px;
      height: 81px;
      border:2px solid rgba(white,0.5);
      background: transparent;
      margin-bottom: 10px;
      padding: 5px;
      font-size: 14px;
      color: white;
      transform: translate(0,0);
      animation: mytip 0.3s forwards ease-in-out;
    }


    .form-submit{
        width: 250px;
        height: 21px;
        border:2px solid rgba(white,0.5);
        background: linear-gradient(to right,#063e64,#061d2f);
        font-size: var(--s-c-size);
        line-height: 21px!important;
        cursor: pointer;

    }
    //.form-submit-tip{
    //  width: 250px;
    //  height: 21px;
    //  border:2px solid rgba(white,0.5);
    //  background: linear-gradient(to right,#063e64,#061d2f);
    //  font-size: var(--s-c-size);
    //  line-height: 21px!important;
    //  cursor: pointer;
    //  transform: translate(0,0);
    //  animation: mytip 0.3s ease-in-out forwards;
    //}
    //.form-submit-tip-1{
    //    width: 180px;
    //    height: 17px;
    //    border:2px solid rgba(white,0.5);
    //    background: linear-gradient(to right,#063e64,#061d2f);
    //    font-size: var(--s-c-size);
    //    line-height: 17px!important;
    //    cursor: pointer;
    //    margin-top: 10px;
    //    transform: translate(0,0);
    //    animation: mytip 0.3s forwards ease-in-out;
    //}
    .form-submit-1{
      width: 180px;
      height: 17px;
      border:2px solid rgba(white,0.5);
      background: linear-gradient(to right,#063e64,#061d2f);
      font-size: var(--s-c-size);
      line-height: 17px!important;
      cursor: pointer;
      margin-top: 10px;
    }
    .form-submit-main{
        background: linear-gradient(to left, var(--s-color1) 0%, #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

    }
    .form-submit-main-1{
        background: linear-gradient(to left, var(--s-color1) 0%, #f2e6cd 90%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 12px;
    }
    .form-footer-top-item4{
        font-size: 14px;
        color: white;
        margin-right: 2%;
        margin-left: 3%;
        width: 280px;
      display: flex;
      flex-direction: column;
    }

    .form-footer-top-item4-top-item{
        margin-top: 20px;
      line-height: 25px;

    }
    .form-footer-top-item4-top-item:first-of-type{
        margin-top: 4px;
    }
    .form-footer-top-item4-bottom{
        display: flex;
        flex-direction: row;
        margin-top: 16px;

    }
    .form-footer-top-item4-bottom-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100px;
    }
    .form-footer-top-item4-bottom-item{
        width: 32px;
        height: 32px;
        margin-left: 19px;
        position: relative;
        cursor: pointer;
    }
    .form-footer-top-item4-bottom-item-1{
        width: 32px;
        height: 32px;
        margin-left: 17px;
        margin-top: 16px;
        position: relative;
        cursor: pointer;
    }

    .xhhc:hover .wx2{
      transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);
      opacity: 1;
    }
    .form-footer-top-item4-bottom-item-1:hover .wx1,.form-footer-top-item4-bottom-item:hover .wx1{
      transform: translateY(-20px) scaleX(1) scaleY(1);
      opacity: 1;
    }
    .form-footer-top-item4-bottom-item:first-of-type{
        margin-left: 0;
    }
    .form-footer-top-item4-bottom-item-1:nth-child(2n-1){
        margin-left: 0;
    }

    .form-footer-top-item5{
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        color: white;
        font-size: 14px;

    }
    .form-footer-top-item5-1{
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        color: white;
        font-size: 7px;
      margin-left: 2%;
      align-self: center;
    }
    .form-footer-top-item5-item{
        margin-left: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
      position: relative;
      cursor: pointer;
    }
    .form-footer-top-item5-item:hover .wx2{
      transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);
      opacity: 1;
    }
    .form-footer-top-item5-item-1{
        margin-left:0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      position: relative;
      @media screen and (max-width: 1300px) {
        margin-left: 0;
      }
    }

    .form-footer-top-item5-item-1:hover .wx2{
      transform: translateY(-20px) translateX(-50%) scaleX(1) scaleY(1);
      opacity: 1;
    }

    .form-footer-top-item5-item:first-of-type,.form-footer-top-item5-item-1:first-of-type{
        margin-left: 0;
    }
    .form-footer-top-item5-item-bottom{
        width: 87px;
        height: 87px;
        margin-top: 14px;
    }
    .form-footer-top-item5-item-bottom-1{
        width: 87px;
        height: 87px;
        margin-bottom: 14px;
    }
    .form-footer-bottom{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:5px 0 ;
        color: #b1b2b3;

    }
    .form-footer-bottom-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:5px 0 ;
        /*height: 100%;*/
        color: #b1b2b3;
        //@media screen and (max-width: 1500px) {
        //    flex-direction: column;
        //}
    }
    .form-footer-bottom-page-1{
        display: flex;
        flex-direction: row;
    }
    .form-footer-bottom-item{
        margin-left: 6px;
        font-size: 14px;
    }
    a.form-footer-bottom-item{
        //cursor: pointer;
    }
    a.form-footer-bottom-item:first-of-type{
        margin-left: 0;

    }

    div.form-footer-bottom-item{
        height: 13px;
        width: 1px;
        background:  #b1b2b3;
        margin-top:2px;
        margin-right: 6px;
        //@media screen and (max-width: 1650px){
        //    margin-right: 6px;
        //    @media screen and (max-width: 1500px){
        //        display: none;
        //    }
        //}

    }
    .tipbox{
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 99;
      transform: translate(-50%,-50%) scaleX(0) scaleY(0);
      opacity: 0;
      width: 250px;
      height: 120px;
      background-image: linear-gradient(to right,rgba(0,123,211,0.9),rgba(0,123,211,0.3));
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      animation: tipbox 3s linear forwards;
    }
    @keyframes tipbox {
      0%{
        transform: translate(-50%,-50%) scaleX(0) scaleY(0);
        opacity: 0;
        z-index: 99;
      }
      30%{
        transform: translate(-50%,-50%) scaleX(1) scaleY(1);
        opacity: 1;
        z-index: 99;
      }
      66.67%{
        transform: translate(-50%,-50%) scaleX(1) scaleY(1);
        opacity: 1;
        z-index: 99;
      }
      100%{
        transform: translate(-50%,-50%) scaleX(1) scaleY(1);
        opacity: 0;
        z-index: -10;
        display: none;
      }
    }
    @keyframes tipbox-1 {
      0%{
        transform: translate(-50%,-50%) scaleX(0) scaleY(0);
        opacity: 0;
      }
      100%{
        transform: translate(-50%,-50%) scaleX(1) scaleY(1);
        opacity: 1;
      }
    }
    .tipbox-left{
      position: absolute;
      left: -2px;
      bottom: -2px;
      background-image: linear-gradient(to bottom,rgba(255,255,255,0.9),rgba(255,255,255,0.9));
      background-size:2px 124px;
      width: 2px;
      height: 124px;
      animation: tipleft 2s linear infinite;
    }
    @keyframes tipleft {
      0%{
        height: 0px;
      }
      83.42%{
        height: 0px;
      }
      100%{
        height: 124px;
      }
    }
    .tipbox-top{
      position: absolute;
      left: 0px;
      top: -2px;
      background-image: linear-gradient(to right,rgba(255,255,255,0.9),rgba(255,255,255,0.3));
      background-size:252px 2px;
      width: 0px;
      height: 2px;
      animation: tiptop 2s linear infinite;
    }
    @keyframes tiptop {
      0%{
        width: 0px;
      }
      33.69%{
        width: 252px;
      }
      100%{
        width: 252px;
      }
    }
    .tipbox-right{
      position: absolute;
      right: -2px;
      top: 0px;
      background-image: linear-gradient(to bottom,rgba(255,255,255,0.3),rgba(255,255,255,0.3));
      background-size:2px 122px;
      width: 2px;
      height: 0px;
      animation: tipright 2s linear infinite;
    }
    @keyframes tipright {
      0%{
        height: 0px;
      }
      33.69%{
        height: 0px;
      }
      50%{
        height: 122px;
      }
      100%{
        height: 122px;
      }
    }
    .tipbox-bottom{
      position: absolute;
      right: 0px;
      bottom: -2px;
      background-image: linear-gradient(to right,rgba(255,255,255,0.9),rgba(255,255,255,0.3));
      background-size:250px 2px;
      width: 0px;
      height: 2px;
      animation: tipbottom 2s linear infinite;
      background-position: 100% 0;
    }

    @keyframes tipbottom {
      0%{
        width: 0px;
      }
      50%{
        width: 0px;
      }
      83.42%{
        width: 250px;
      }
      100%{
        width: 250px;
      }
    }
    ::-webkit-input-placeholder{
      color: red;
    }
    .tick{
      stroke-dasharray:350;
      stroke-dashoffset: 350;
      animation: tick 0.4s ease-in-out forwards;
      animation-delay: 0.3s;
    }
    .fail-left{
      stroke-dasharray:80;
      stroke-dashoffset: 80;
      animation: fail-left 0.2s ease-in-out forwards;
      animation-delay: 0.3s;
    }
    .fail-right{
      stroke-dasharray:80;
      stroke-dashoffset: 80;
      animation: fail-left 0.2s ease-in-out forwards;
      animation-delay: 0.5s;
    }
    @keyframes fail-left {
      0%{
        stroke-dashoffset: 80;
      }
      100%{
        stroke-dashoffset: 0;
      }
    }
    @keyframes tick {
      0%{
        stroke-dashoffset: 350;
      }
      100%{
        stroke-dashoffset: 0;
      }
    }
    .svg{
      background-color: white;
      border-radius: 50%;
    }
    .tipbox-1{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transition: 0.3s;
      transform: translate(-50%,-50%) scaleX(0) scaleY(0);
      animation: tipbox-1 0.3s linear forwards;
    }
    .tipbox-success{
      font-size: 25px;
      margin-top: 9px;
    }
    .tipbox-remark{
      font-size: 14px;
      color: gray;
      text-align: center;
      margin-top: 14px;
    }
    .tipbox-time{
      font-size: 12px;
      color: gray;
      margin-top: 8px;
    }
    .form-footer-top-item5-item-top-1{
      width: 150px;
      font-size: 13px;
      text-align: center;
    }

    .hoverpadding{
      position: absolute;
      left: 0;
      top: -50px;
      bottom: 0;
      right: 0;
      display: none;
    }
    .xhhc:hover .hoverpadding{
      display: block;
    }
    .form-footer-top-item5-item-1:hover .hoverpadding{
      display: block;
    }
    .form-footer-top-item5-item:hover .hoverpadding{
      display: block;
    }
</style>
